import type { MetaFunction } from 'react-router';
import { data } from 'react-router';
import { Form, redirect, useActionData, useLoaderData } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { getMe } from '@/api_clients/trainers/user_client';
import { Button } from '@/components/button';
import { InputEmail } from '@/components/trainers/login/input_email';
import { InputPassword } from '@/components/trainers/login/input_password';
import usePasswordAuth from '@/hooks/use_password_auth';
import EqutumLogoHorizontal from '@/images/equtum_logo_h.svg?react';
import { firebaseConfig } from '@/utils/auth.server';
import { authenticate, createLoginCookies } from '@/utils/session.server';
import { setToast } from '@/utils/toast.server';

export const meta: MetaFunction = () => {
  return [{ title: 'EQUTUM | 厩舎ログイン' }];
};

export const loader = async ({ request }: { request: Request }) => {
  const { idToken } = await authenticate(request).catch(() => ({ idToken: null }));

  if (idToken) {
    return redirect('/');
  }

  return {
    firebaseConfig,
  };
};

export default function Login() {
  const { firebaseConfig } = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const {
    user,
    error,
    signIn,
    setEmail,
    setPassword,
    isSubmitting: passwordIsSubmitting,
  } = usePasswordAuth(firebaseConfig);
  const isSubmitting = passwordIsSubmitting || formIsSubmitting;

  const formRef = useRef<HTMLFormElement>(null);
  const [idToken, setIdToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [expirationTime, setExpirationTime] = useState<string | null>(null);

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        setIdToken(idTokenResult.token);
        setRefreshToken(user.refreshToken);
        setExpirationTime(idTokenResult.expirationTime);
      });
    }
  }, [user]);

  useEffect(() => {
    if (idToken && refreshToken && expirationTime) {
      setFormIsSubmitting(true);
      formRef.current?.submit();
    }
  }, [idToken, refreshToken, expirationTime]);

  useEffect(() => {
    setFormIsSubmitting(false);
  }, [error, actionData?.error]);

  return (
    <div className="mx-auto flex min-h-svh w-full flex-col items-center overflow-hidden bg-surface-base">
      <div className="flex w-full max-w-xs flex-col items-center">
        <EqutumLogoHorizontal />
        <div className="mt-6 flex w-full flex-col whitespace-nowrap">
          <div className="w-full gap-5 text-3xl font-bold leading-tight text-glyphs-normal">
            厩舎ログイン
          </div>
        </div>
        <div className="text-negative">
          {actionData?.error && <div key={actionData.error}>{actionData.error}</div>}
          {error && <div>{error.message}</div>}
        </div>
        <Form
          ref={formRef}
          className="flex w-full flex-col items-start gap-4"
          method="post"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              buttonRef.current?.click();
            }
          }}
        >
          {idToken && refreshToken && expirationTime && (
            <>
              <input name="id_token" type="hidden" value={idToken} />
              <input name="refresh_token" type="hidden" value={refreshToken} />
              <input name="expiration_time" type="hidden" value={expirationTime} />
            </>
          )}
          <div className="mt-6 flex w-full flex-col">
            <InputEmail setEmail={setEmail} />
            <InputPassword setPassword={setPassword} />
          </div>
          <div className="mt-6 flex max-w-full flex-col items-center self-center whitespace-nowrap">
            <Button
              ref={buttonRef}
              className="min-h-[40px] w-60 max-w-full gap-1 rounded-sm bg-indigo-500 px-4 py-3 text-center text-base font-medium leading-none tracking-wider text-white disabled:bg-disabled"
              color="primary"
              disabled={isSubmitting}
              size="large"
              type="button"
              variant="filled"
              onClick={signIn}
            >
              {isSubmitting ? 'ログイン中...' : 'ログイン'}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export const action = async ({ request }: { request: Request }) => {
  const formData = await request.formData();
  const idToken = formData.get('id_token') as string;
  const refreshToken = formData.get('refresh_token') as string;
  const expirationTime = new Date(formData.get('expiration_time') as string);
  try {
    const me = await getMe(idToken);
    if (me === null) {
      return data(
        { error: '登録されたユーザーが見つかりませんでした。アカウント情報をお確かめください。' },
        { status: 400 }
      );
    }
  } catch (error) {
    return data(
      { error: '登録されたユーザーが見つかりませんでした。アカウント情報をお確かめください。' },
      { status: 400 }
    );
  }

  try {
    const cookies = createLoginCookies(idToken, refreshToken, expirationTime);
    const headers = await setToast({ type: 'success', content: 'ログインに成功しました' }, request);

    for (const cookie of cookies) {
      headers.append('Set-Cookie', cookie);
    }
    return redirect('/', {
      headers,
    });
  } catch (error) {
    console.error(error);
    return data({ error: 'メールアドレスまたはパスワードが誤っています' }, { status: 400 });
  }
};
